import React from 'react';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import FlightLandOutlinedIcon from '@mui/icons-material/FlightLandOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import ClosedCaptionDisabledOutlinedIcon from '@mui/icons-material/ClosedCaptionDisabledOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import CategoryIcon from '@mui/icons-material/Category';

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <DashboardOutlinedIcon fontSize="small" />,
    componentPath: "layouts/dashboard",
    noCollapse: true,
    permission: "dashboard",
  },
  { type: "title", title: "Allgemeine Informationen", key: "infos", permission: "dashboard" },
  {
    type: "collapse",
    name: "Regelwerk",
    key: "team-regelwerk",
    route: "/team-regelwerk",
    icon: <FormatListNumberedIcon fontSize="small" />,
    componentPath: "layouts/infos/team-regelwerk",
    noCollapse: false,
    permission: "dashboard",
  },
  {
    type: "collapse",
    name: "Aufgabenbereiche",
    key: "team-aufgabenbereiche",
    route: "/team-aufgabenbereiche",
    icon: <PeopleAltOutlinedIcon fontSize="small" />,
    componentPath: "layouts/infos/team-aufgabenbereiche",
    noCollapse: false,
    permission: "dashboard",
  },
  {
    type: "collapse",
    name: "Einreise & RWA",
    key: "team-einreise",
    route: "/team-einreise",
    icon: <FlightLandOutlinedIcon fontSize="small" />,
    componentPath: "layouts/infos/team-einreise",
    noCollapse: false,
    permission: "dashboard",
  },
  {
    type: "collapse",
    name: "Strafkatalog",
    key: "team-strafkatalog",
    route: "/team-strafkatalog",
    icon: <ReportGmailerrorredOutlinedIcon fontSize="small" />,
    componentPath: "layouts/infos/team-strafkatalog",
    noCollapse: false,
    permission: "dashboard",
  },
  {
    type: "collapse",
    name: "Verwaltungsübersicht",
    key: "team-verwaltungen",
    route: "/team-verwaltungen",
    icon: <BuildCircleOutlinedIcon fontSize="small" />,
    componentPath: "layouts/infos/team-verwaltungen",
    noCollapse: false,
    permission: "dashboard",
  },
  {
    type: "collapse",
    name: "Feedback",
    key: "team-feedback",
    route: "/team-feedback",
    icon: <MarkEmailReadOutlinedIcon fontSize="small" />,
    componentPath: "layouts/infos/team-feedback",
    noCollapse: false,
    permission: "dashboard",
  },
  {
    type: "collapse",
    name: "Mentor-Feedback",
    key: "mentor-feedback",
    route: "/mentor-feedback",
    icon: <MarkEmailReadOutlinedIcon fontSize="small" />,
    componentPath: "layouts/infos/mentor-feedback",
    noCollapse: false,
    permission: "teamleitung/863492133171494965/863492133171494962/863492133150392366/863492133150392365/863492133150392362/863492133150392361",
  },
  { type: "title", title: "Analyse", key: "analyse", permission: "teamleitung/903633713378787378" },
  {
    type: "collapse",
    name: "Allgemeines",
    key: "analyse-allgemein",
    route: "/analyse-allgemein",
    icon: <ClosedCaptionDisabledOutlinedIcon fontSize="small" />,
    componentPath: "layouts/infos/analyse-allgemein",
    noCollapse: false,
    permission: "teamleitung/903633713378787378",
  },
  {
    type: "collapse",
    name: "Ticketbearbeitung",
    key: "analyse-tickets",
    route: "/analyse-tickets",
    icon: <LocalActivityOutlinedIcon fontSize="small" />,
    componentPath: "layouts/infos/analyse-tickets",
    noCollapse: false,
    permission: "teamleitung/903633713378787378",
  },
  {
    type: "collapse",
    name: "Analyseablauf",
    key: "analyse-ablauf",
    route: "/analyse-ablauf",
    icon: <PersonSearchOutlinedIcon fontSize="small" />,
    componentPath: "layouts/infos/analyse-ablauf",
    noCollapse: false,
    permission: "teamleitung/903633713378787378",
  },
  { type: "title", title: "Entbannungsverwaltung", key: "eb", permission: "teamleitung/865739777058799656" },
  {
    type: "collapse",
    name: "Allgemeines",
    key: "entbannung-allgemein",
    route: "/entbannung-allgemein",
    icon: <ClosedCaptionDisabledOutlinedIcon fontSize="small" />,
    componentPath: "layouts/infos/entbannung-allgemein",
    noCollapse: false,
    permission: "teamleitung/865739777058799656",
  },
  {
    type: "collapse",
    name: "Herangehensweise",
    key: "entbannung-herangehensweise",
    route: "/entbannung-herangehensweise",
    icon: <InfoOutlinedIcon fontSize="small" />,
    componentPath: "layouts/infos/entbannung-herangehensweise",
    noCollapse: false,
    permission: "teamleitung/865739777058799656",
  },
  {
    type: "collapse",
    name: "Entbannungskatalog",
    key: "entbannung-katalog",
    route: "/entbannung-katalog",
    icon: <MenuBookOutlinedIcon fontSize="small" />,
    componentPath: "layouts/infos/entbannung-katalog",
    noCollapse: false,
    permission: "teamleitung/865739777058799656",
  },
  {
    type: "collapse",
    name: "Vorlagen",
    key: "entbannung-vorlagen",
    route: "/entbannung-vorlagen",
    icon: <DescriptionOutlinedIcon fontSize="small" />,
    componentPath: "layouts/infos/entbannung-vorlagen",
    noCollapse: false,
    permission: "teamleitung/865739777058799656",
  },
  { type: "title", title: "Hilfreiches", key: "helpful", permission: "teamleitung/864500228463525919/863492133171494962/863492133171494962/873616089240698880/1228221257384988682" },
  {
    type: "collapse",
    name: "Multiservercheck",
    key: "multiservercheck",
    route: "/multiservercheck",
    icon: <BarChartOutlinedIcon fontSize="small" />,
    componentPath: "layouts/multiservercheck",
    noCollapse: false,
    permission: "teamleitung/864500228463525919/863492133171494962",
  },
  {
    type: "collapse",
    name: "Itemliste",
    key: "itemlist",
    route: "/itemlist",
    icon: <CategoryIcon fontSize="small" />,
    componentPath: "layouts/itemlist",
    noCollapse: false,
    permission: "teamleitung/863492133171494966/873616089240698880/1228221257384988682",
  },
  {
    type: "collapse",
    name: "Fooditemliste",
    key: "fooditemlist",
    route: "/fooditemlist",
    icon: <CategoryIcon fontSize="small" />,
    componentPath: "layouts/fooditemlist",
    noCollapse: false,
    permission: "teamleitung/863492133171494966/873616089240698880/1228221257384988682",
  },
  { type: "title", title: "Teamleitung", key: "tl", permission: "teamleitung/863492133150392364" },
  {
    type: "collapse",
    name: "Übersicht",
    key: "teamleitung-overview",
    route: "/teamleitung-overview",
    icon: <BarChartOutlinedIcon fontSize="small" />,
    componentPath: "layouts/teamleitung/overview",
    noCollapse: false,
    permission: "teamleitung/863492133150392364",
  },
  {
    type: "collapse",
    name: "Detail-Übersicht",
    key: "overview-detail",
    route: "/overview-detail",
    icon: <QueryStatsIcon fontSize="small" />,
    componentPath: "layouts/teamleitung/overview-detail",
    noCollapse: false,
    permission: "teamleitung/863492133150392364",
  },
];

export default routes;